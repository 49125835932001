<template>
  <section v-if="countryMissing">
    <div class="text-center">
      <h1 class="text-xl font-bold mb-3">Vakifbank {{ $t("calculator") }}</h1>
      <p>{{ $t("forwhichcountry") }}</p>
      <router-link
        v-if="austriaAvailable"
        :to="'/' + $i18n.locale + '/ertragsrechner?country=at'"
      >
        <div class="font-bold p-2 m-2 rounded shadow border hover:bg-slate-100">
          {{ $t("aut") }} 🇦🇹
        </div>
      </router-link>
      <router-link
        v-if="germanyAvailable"
        :to="'/' + $i18n.locale + '/ertragsrechner?country=de'"
      >
        <div class="font-bold p-2 m-2 rounded shadow border hover:bg-slate-100">
          {{ $t("ger") }} 🇩🇪
        </div>
      </router-link>
      <router-link
        v-if="hungaryAvailable"
        :to="'/' + $i18n.locale + '/ertragsrechner?country=hu'"
      >
        <div class="font-bold p-2 m-2 rounded shadow border hover:bg-slate-100">
          {{ $t("hungary") }} 🇭🇺
        </div>
      </router-link>
    </div>
  </section>
  <section v-else-if="modeMissing">
    <div class="text-center">
      <h1 class="text-xl font-bold mb-3">
        Vakifbank {{ $t("calculator") }} {{ flag }}
      </h1>
      <p>{{ $t("forwhichaccounttype") }}</p>
      <router-link
        v-if="festGeldAvailable"
        :to="`/${$i18n.locale}/ertragsrechner?country=${$route.query.country}&mode=festgeld`"
      >
        <div class="font-bold p-2 m-2 rounded shadow border hover:bg-slate-100">
          {{ $t("timedeposit") }}
        </div>
      </router-link>
      <router-link
        v-if="sparbuchAvailable"
        :to="`/${$i18n.locale}/ertragsrechner?country=${$route.query.country}&mode=sparbuch`"
      >
        <div class="font-bold p-2 m-2 rounded shadow border hover:bg-slate-100">
          {{ $t("savingsbook") }}
        </div>
      </router-link>
    </div>
  </section>
  <section v-else-if="modeAndCountryOkay || $route.query.specificID">
    <form @submit.prevent="onSubmit">
      <h2 class="font-bold text-2xl mb-4 flex" v-if="!$route.query.specificID">
        <div v-if="$route.query.mode == 'festgeld'">
          {{ $t("timedeposit") }} {{ $t("calculator") }}
        </div>
        <div v-else-if="$route.query.mode == 'sparbuch'">
          {{ $t("savingsbook") }} {{ $t("calculator") }}
        </div>
        <div class="ml-2">
          {{ flag }}
        </div>
      </h2>
      <h2
        v-else
        class="bg-vakifbank text-xl text-white font-bold p-2 rounded mb-2"
      >
        {{ $t("calculator") }} Test-Mode!
      </h2>
      <p v-if="!$route.query.specificID">
        {{ $t("calcExplained") }}
      </p>
      <div
        v-if="
          festGeldAvailable && sparbuchAvailable && !$route.query.hideOptions
        "
      >
        <label class="block text-sm font-bold my-2" for="mode"
          >{{ $t("investmentMode") }}:</label
        >
        <select
          id="mode"
          class="select w-full shadow border border-gray-200"
          :class="$route.query.specificID ? 'disabled' : ''"
          aria-required="true"
          v-model="mode"
          :disabled="$route.query.specificID ? true : false"
          @change="
            $router.push(
              `/${$i18n.locale}/ertragsrechner?country=${$route.query.country}&mode=${mode}`
            )
          "
        >
          <option disabled selected>{{ $t("plsChoose") }}:</option>
          <option
            value="festgeld"
            v-if="festGeldAvailable"
            :selected="$route.query.mode == 'festgeld' ? true : false"
          >
            {{ $t("timedeposit") }}
          </option>
          <option
            value="sparbuch"
            v-if="sparbuchAvailable"
            :selected="$route.query.mode == 'sparbuch' ? true : false"
          >
            {{ $t("savingsbook") }}
          </option>
        </select>
      </div>
      <div>
        <label class="block text-sm font-bold my-2" for="betrag"
          >{{ $t("amount") }} in €
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="betrag"
          v-model="betrag"
          :min="minBetrag"
          type="number"
          required
          aria-required="true"
          step="1"
          :placeholder="$t('amount') + ' in €'"
        />
      </div>
      <div class="py-2">
        <Anlagedauer
          v-if="minMonths.length > 0"
          v-model="anlageDauerMonate"
          :options="minMonths"
        />
      </div>
      <div>
        <p class="font-bold text-lg">
          {{ $t("interest") }}: {{ zinsen }}% p.a.
        </p>
        <p>
          {{ $t("totalInterest") }} {{ anlageDauerMonate }} {{ $t("months") }}:
        </p>
        <p class="font-bold text-lg">
          {{ number_format(verzinsterBetrag) }} Euro
        </p>
        <p class="text-sm">{{ $t("keepKEST") }}</p>
      </div>
      <div v-if="$route.query.country != 'hu'">
        <div
          class="flex justify-end py-3"
          v-if="
            (mode == 'festgeld' && !$route.query.specificID) ||
            queryMode == 'festgeld'
          "
        >
          <a
            :href="`/de/konto-erstellen?betrag=${betrag}&anlagedauer=${anlageDauerMonate}&country=${$route.query.country}`"
            target="__blank"
            class="btn btn-accent text-white"
            >{{ $t("timedeposit") }} {{ $t("createAccount") }}</a
          >
        </div>
      </div>
    </form>
  </section>
  <section class="text-center" v-else>
    <h3 class="text-xl font-bold">{{ $t("didntWork") }}</h3>
    <p>
      {{ $t("didntWorkExplained") }}
    </p>
  </section>
</template>
<script>
import axios from "axios";
import Anlagedauer from "@/components/Anlagedauer.vue";

export default {
  components: {
    Anlagedauer,
  },
  data() {
    return {
      anlageDauerMonate: 6,
      zinsen: 0,
      betrag: 0,
      mode: "",
      country: undefined,
      zinsModi: [],
      minMonths: [], // used for anlagedauer options
      minBetrag: 0,
    };
  },
  watch: {
    anlageDauerMonate() {
      this.updateZinsen();
    },
    mode(newMode) {
      if (newMode == "festgeld" && this.modeAndCountryOkay) {
        // Handle different default values based on country and mode
        if (this.$route.query.country === "at" && this.festGeldAvailable) {
          this.betrag = 1000; // Specific value for festgeld in Austria
        } else if (
          this.$route.query.country === "hu" &&
          this.festGeldAvailable
        ) {
          this.betrag = 5000;
        } else {
          this.betrag = 5000;
        }
        return this.calculateFestgeld();
      } else if (newMode == "sparbuch" && this.modeAndCountryOkay) {
        if (this.$route.query.country === "at" && this.sparbuchAvailable) {
          this.betrag = 1000; // Specific value for sparbuch in Austria
        } else if (
          this.$route.query.country === "hu" &&
          this.sparbuchAvailable
        ) {
          this.betrag = 2000; // Example: Specific value for sparbuch in Hungary
        } else {
          this.betrag = 3000; // Default value for sparbuch
        }
        return this.calculateSparbuch();
      }
      this.minBetrag = this.betrag;
    },
  },
  computed: {
    queryCountry() {
      return this.$route.query.country;
    },
    queryMode() {
      return this.$route.query.mode;
    },
    austriaAvailable() {
      return this.zinsModi.some((zinsModi) => zinsModi.country == "at");
    },
    germanyAvailable() {
      return this.zinsModi.some((zinsModi) => zinsModi.country == "de");
    },
    hungaryAvailable() {
      return this.zinsModi.some((zinsModi) => zinsModi.country == "hu");
    },
    festGeldAvailable() {
      return this.zinsModi.some(
        (zinsModi) =>
          zinsModi.country == this.$route.query.country &&
          zinsModi.mode == "festgeld"
      );
    },
    sparbuchAvailable() {
      return this.zinsModi.some(
        (zinsModi) =>
          zinsModi.country == this.$route.query.country &&
          zinsModi.mode == "sparbuch"
      );
    },
    verzinsterBetrag() {
      if (this.$route.query.mode == "festgeld") {
        return this.calculateFestgeld();
      } else if (this.$route.query.mode == "sparbuch") {
        return this.calculateSparbuch();
      }
      return 0;
    },
    flag() {
      switch (this.$route.query.country) {
        case "at":
          return "🇦🇹";
        case "de":
          return "🇩🇪";
        case "hu":
          return "🇭🇺";
        default:
          return "";
      }
    },
    countryMissing() {
      const validCountries = ["at", "de", "hu"];
      return (
        !this.$route.query.specificID &&
        (!this.$route.query.country ||
          !validCountries.includes(this.$route.query.country.toLowerCase()))
      );
    },
    modeMissing() {
      return !["festgeld", "sparbuch"].includes(this.$route.query.mode);
    },
    modeSetViaGet() {
      return ["festgeld", "sparbuch"].includes(this.$route.query.mode);
    },
    modeAndCountryOkay() {
      if (this.$route.query.mode == "festgeld") {
        return this.festGeldAvailable;
      } else if (this.$route.query.mode == "sparbuch") {
        return this.sparbuchAvailable;
      }
      return false;
    },
  },
  created() {
    if (this.$route.query.specificID) {
      this.mode = this.$route.query.mode;
    }

    if (this.$route.query.mode == "festgeld") {
      this.mode = "festgeld";
      if (this.$route.query.country === "at") {
        this.betrag = 1000;
      } else if (this.$route.query.country === "hu") {
        this.betrag = 5000; // Example: default for Hungary
      } else {
        this.betrag = 5000;
      }
    } else if (this.$route.query.mode == "sparbuch") {
      this.mode = "sparbuch";
      this.betrag = this.$route.query.country === "at" ? 1000 : 3000;
    }

    this.minBetrag = this.betrag;

    this.loadValidOptions(); // für die monatsauswahl
    this.zinsModi = [];
    // load options
    axios.get(process.env.VUE_APP_ADMIN_BACKEND + "zinsModi").then((res) => {
      this.zinsModi = res.data;
    });

    if (!this.countryMissing && !this.modeMissing) {
      this.updateZinsen();
    }
  },
  methods: {
    loadValidOptions() {
      this.minMonths = [];
      this.anlageDauerMonate = 6;

      let request_endpoint;
      if (!this.$route.query.specificID) {
        request_endpoint = `zinsRules?mode=${this.$route.query.mode}&country=${this.$route.query.country}`;
      } else {
        request_endpoint = `zinsRules?specificID=${this.$route.query.specificID}`;
      }
      axios
        .get(process.env.VUE_APP_ADMIN_BACKEND + request_endpoint)
        .then((res) => {
          res.data.zinsRules.forEach((zR) => {
            this.minMonths.push(zR.minMonths);
          });
          this.anlageDauerMonate = Number(res.data.zinsRules[0].minMonths);
        });
    },
    updateZinsen() {
      this.zinsen = 0;
      let request_endpoint;
      if (!this.$route.query.specificID) {
        request_endpoint = `zinsBerechnung?mode=${this.$route.query.mode}&anlageDauer=${this.anlageDauerMonate}&country=${this.$route.query.country}`;
      } else {
        request_endpoint = `zinsBerechnung?specificID=${this.$route.query.specificID}&anlageDauer=${this.anlageDauerMonate}`;
      }

      axios
        .get(process.env.VUE_APP_ADMIN_BACKEND + request_endpoint)
        .then((res) => {
          this.zinsen = Number(res.data.zinsen);
        })
        .catch((e) => {
          this.zinsen = 0;
          console.log(e);
          this.$store.dispatch("newResponse", {
            message: "Mit diesen Informationen können wir keine Antwort geben",
            success: false,
          });
        });
    },
    calculateFestgeld() {
      const schnittTageProMonat = 30;
      const anlageInTagen = Math.floor(
        this.anlageDauerMonate * schnittTageProMonat
      );
      const zinsbetrag = (this.betrag * this.zinsen * anlageInTagen) / 36000;
      return this.betrag + zinsbetrag;
    },
    calculateSparbuch() {
      // Check if the duration is 6 or 9 months
      if (this.anlageDauerMonate === 6 || this.anlageDauerMonate === 9) {
        const schnittTageProMonat = 30; // Average days per month
        const anlageInTagen = Math.floor(
          this.anlageDauerMonate * schnittTageProMonat
        ); // Total duration in days
        const zinsbetrag = (this.betrag * this.zinsen * anlageInTagen) / 36000; // Interest amount

        // Calculate the final amount
        const result = this.betrag + zinsbetrag;

        // Log the calculation formula and result
        console.log(
          `f(x) = ${this.betrag} + (${this.betrag} * ${
            this.zinsen
          } * ${anlageInTagen}) / 36000 = ${result.toFixed(2)}`
        );

        return result;
      } else if (this.anlageDauerMonate >= 12) {
        // If the duration is more than a year, calculate using compound interest
        let zinsdings = 1 + Number(this.zinsen / 100);
        let hochdings = this.anlageDauerMonate / 12;
        let mathPowResult = zinsdings ** hochdings;
        let result = this.betrag * mathPowResult;
        console.log(
          `f(x) = ${
            this.betrag +
            " x (1 + " +
            this.zinsen / 100 +
            ") ^ (" +
            this.anlageDauerMonate +
            "/12)"
          } = ${result}`
        );
        return result;
      } else {
        
        return this.calculateFestgeld();
      }
    },
    number_format(number, decimals, dec_point, thousands_sep) {
      let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof thousands_sep === "undefined" ? "." : thousands_sep,
        dec = typeof dec_point === "undefined" ? "," : dec_point,
        toFixedFix = function (n, prec) {
          let k = Math.pow(10, prec);
          return Math.round(n * k) / k;
        },
        s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split(".");
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
      }
      if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
      }
      return s.join(dec);
    },
  },
};
</script>
